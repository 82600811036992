@import 'antd/dist/antd.css';

.App {
  text-align: center;
}
*{
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.avatar-uploader > .ant-upload {
  width: 80%;
  height: 200px;
  margin-left:150px;
  margin-right:200px;
  margin-top :20px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.whitechilds * {
  color: #fff !important;
}
.ag-theme-alpine{
  --ag-header-foreground-color: rgb(0, 107, 181);
  --ag-header-background-color: #f5f5f5;
  --ag-borders: none; 
   --ag-borders-row: none;
}


.approveBtnStyle:disabled {
 color: #565656 !important;
 cursor:not-allowed !important;
}
.approveBtnStyle{
  border: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  padding: 0 0 2px 0;
}
.ag-theme-alpine .ag-header-cell {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  font-size: 15px;
  border: none !important;
  background-color: #f7f7f8;
  color:rgba(0, 0, 0, 0.85);
  letter-spacing: 1px;
  font-weight: 350;
}


.ag-theme-alpine .ag-header-group-cell {
  font-size: 15px;
  background-color: #f7f7f8;
  /* background-color: #f0f9ff; */
  font-weight: 490;
  letter-spacing: 2px;
  font-weight: 600!important;

}
.ag-header-cell-resize{
  opacity: 0;
}
.ag-root-wrapper {
  border: 1px solid #ccc !important;
  border-radius: .5rem;
}
.centerHeader .ag-header-group-text{
  margin-left: 50%;
transform: translate(-50%);
}
.ant-timeline-item-head {
  width: 15px;
  height: 15px;
}
.ant-timeline-item{
  font-size: 16px;
}

.ag-row{
  border: none !important;
  border-radius: 4px;
  height: 20px;
  background-color: #f8f7fc !important;
  color: #444 !important;

}
.ag-theme-alpine .ag-body {
  border-spacing: 0 10px;
} 

.ag-body-viewport::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.ag-body-viewport::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.ag-body-viewport::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}