body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f7fc;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  background-image: url("./images/brand.png");
  width: 47px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
}

.nav-left {
  display: flex;
  flex-wrap: wrap;
  float: left;
}
.nav-right {
  display: flex;
  flex-wrap: wrap;
  float: right;
}

.site-layout .site-layout-background {
  background: #f8f7fc;
}

.ant-page-header .ant-page-header-heading {
  margin-bottom: -15px;
  padding: 0.5rem 0;
}

.ant-page-header-heading {
  display: flex;
  align-items: center;
}

.ant-page-header-heading-title h3 {
  margin-bottom: 0 !important;
}

.ant-menu.ant-menu-light {
  color: black;
  font-weight: normal;
}


.ant-carousel .slick-dots li {
  background: gray;
}

.ant-steps-item-process .ant-steps-item-tail::after {
  background: rgb(216, 213, 221) !important;
}

.ant-tabs-nav-more {
  background: white !important;
  color: rgb(85, 164, 213);
  font-weight: bold;
}

.journal-container .ant-tabs-tab-active {
  background: white;
}
.ant-menu-horizontal {
  border: none !important;
}
.ant-tabs {
  border:none  !important;
}
.ant-tabs-nav::before{
  border: none !important  ;
}
.ant-page-header-content{
  background-color: #f8f7fc;
}
.ant-btn-primary{
  background-color: #d4a30fd1!important;
  border: #6f52ee !important;
}
.ant-select-selector{
  border: none !important;
}
.ant-tabs-tab,.ant-tabs-tab-btn{
  background-color: #f8f7fc!important;
  border: none!important;
}
.ant-tabs-nav-wrap{
/* border-bottom:3px solid #eee1ff !important ;
border-top:3px solid #eee1ff !important ; */
}